import React, { useEffect, useState } from 'react';
import { bool, func, number, shape } from 'prop-types';

import classnames from 'classnames';
import AndesTooltip from '@andes/tooltip';

import useTooltip from '../../../../hooks/use-tooltip';
import Actions from '../../../rescue/components/actions';
import { getFilterTooltipKey, isTTLActive } from './helpers';
import { MOBILE_DEFAULT_PROPS, MOBILE_PROP_TYPES } from './constants';
import { trackEvent } from '../../../../lib/melidata';

const namespace = 'ui-search-tooltip-filter';

const FilterTooltip = ({
  className,
  children,
  mask,
  maskClosable,
  onClose,
  tooltip,
  tooltipStatus,
  hasTTLExpired,
  onPermanentClose,
}) => {
  const {
    title,
    offset_x,
    offset_y,
    content,
    filter_content_id,
    max_print,
    actions,
    side,
    tracks,
    close_limit,
    with_ttl,
  } = tooltip || {};
  const { wasClosed, views, countClose, isPermanentClosed, createdDate } = tooltipStatus || {};
  const [visible, setVisible] = useState(false);
  const isTooltipEligibleDisplay = !(wasClosed || views > max_print || !filter_content_id);
  const handleCloseTooltip = () => {
    setVisible(false);

    if (tracks?.close) {
      trackEvent({ clean: true, ...tracks.close });
    }

    onClose();
  };

  const handleOnclickAction = (handleCustomClick, action) => {
    if (handleCustomClick) {
      handleCustomClick();
    }

    if (action?.isPermanentClosed) {
      onPermanentClose();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    let isVisible = isTooltipEligibleDisplay;

    if (with_ttl) {
      const tooltipValidation = {
        isPermanentClosed,
        with_ttl,
        hasTTLExpired,
        closeLimit: close_limit,
        countClose,
        createdDate,
      };

      isVisible = isTTLActive(tooltipValidation);
    }

    setVisible(isVisible);

    if (tracks?.show && isVisible) {
      trackEvent({ clean: true, ...tracks.show });
    }
  }, [
    tracks,
    hasTTLExpired,
    close_limit,
    countClose,
    isPermanentClosed,
    with_ttl,
    createdDate,
    wasClosed,
    isTooltipEligibleDisplay,
  ]);

  return (
    <AndesTooltip
      actions={<Actions actions={actions} actionButton={handleOnclickAction} />}
      className={classnames(namespace, className)}
      closable
      onClose={handleCloseTooltip}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      mask={mask}
      maskClosable={maskClosable}
      side={side}
      type="highlight"
      trigger={isTooltipEligibleDisplay ? 'click' : 'no-trigger'}
      visible={visible}
    >
      <div className={classnames({ 'andes-tooltip__trigger--visible': visible })}>{children}</div>
    </AndesTooltip>
  );
};

const FilterTooltipContainer = ({ tooltip, ...props }) => {
  const localStorageKey = getFilterTooltipKey(tooltip);
  const [tooltipStatus, handleClose, hasTTLExpired, handleOnNext, isTooltipLoad] = useTooltip(
    localStorageKey,
    tooltip?.ttl,
  );

  if (!isTooltipLoad) {
    return null;
  }

  return (
    <FilterTooltip
      {...props}
      tooltip={tooltip}
      tooltipStatus={tooltipStatus}
      onClose={handleClose}
      hasTTLExpired={hasTTLExpired}
      onPermanentClose={handleOnNext}
    />
  );
};

FilterTooltip.propTypes = {
  ...MOBILE_PROP_TYPES,
  hasTTLExpired: func,
  tooltipStatus: shape({
    wasClosed: bool.isRequired,
    views: number.isRequired,
    countClose: number,
    isPermanentClosed: bool,
  }).isRequired,
  onClose: func.isRequired,
  onPermanentClose: func,
};
FilterTooltip.defaultProps = MOBILE_DEFAULT_PROPS;

FilterTooltipContainer.propTypes = MOBILE_PROP_TYPES;
FilterTooltipContainer.defaultProps = MOBILE_DEFAULT_PROPS;

export default FilterTooltipContainer;
export { FilterTooltip };
