import React from 'react';

import useTooltip from '../../../../../hooks/use-tooltip';
import { FilterTooltip } from '../../../../../components/sidebar/components/filters-tooltip/filters-tooltip.mobile';
import { getFilterTooltipKey } from '../../../../../components/sidebar/components/filters-tooltip/helpers';
import { trackClickEvent } from '../../../../../components/map/track';
import { namespaceToolbal as namespace, MapLinkMobileProps, ICONS } from '../constants';

const MapLinkMobile = ({
  map_link: { analytics_track_event = null, melidata_track_value = null, label, url, tooltip = null, type },
}) => {
  const tooltipKey = getFilterTooltipKey(tooltip);
  const [tooltipStatus, closeTooltip] = useTooltip(tooltipKey);

  const handleClick = () => {
    if (analytics_track_event && melidata_track_value) {
      trackClickEvent(analytics_track_event, melidata_track_value);
    }

    closeTooltip();
  };

  const { IconComponent, className } = ICONS[type] || ICONS.MAP_LINK;

  return (
    <li className={`${namespace}__action`}>
      <a className={`${namespace}__link`} href={url} onClick={handleClick}>
        <IconComponent className={`${namespace}__icon--${className}`} />
        {label}
      </a>
      <FilterTooltip
        className={`${namespace}__tooltip`}
        mask
        maskClosable={false}
        onClose={closeTooltip}
        side="bottomLeft"
        tooltip={tooltip}
        tooltipStatus={tooltipStatus}
      >
        <div className={`${namespace}__tooltip-trigger`} />
      </FilterTooltip>
    </li>
  );
};

MapLinkMobile.propTypes = {
  ...MapLinkMobileProps,
};

export default MapLinkMobile;
