const redirectTo = (url, { replaceRules = null } = {}) => {
  let updatedUrl = url;

  if (replaceRules) {
    Object.entries(replaceRules).forEach(([searchValue, replaceValue]) => {
      updatedUrl = updatedUrl.replace(searchValue, replaceValue);
    });
  }

  window.location.href = updatedUrl;
};

export default redirectTo;
